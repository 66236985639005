import { DateTime } from "luxon";

export function dateFromISO(val: string | DateTime) {
  if (typeof val === "string") return DateTime.fromISO(val).setLocale("nb");
  return val;
}

export function dateFormat(val: string | DateTime) {
  return dateFromISO(val).toLocaleString({
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function dateTimeFormat(val: string | DateTime) {
  return dateFromISO(val).toLocaleString({
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: undefined,
  });
}
