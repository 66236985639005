import { DateTime } from "luxon";
import { IReport } from "../types";
import { dateFromISO } from "./date";

export function attend(
  sessionNumber: number,
  present: Array<number> = [],
  selfStudy: Array<number> = []
) {
  if (present.includes(sessionNumber)) return "U";
  if (selfStudy.includes(sessionNumber)) return "S";
  return "-";
}

export function summarize(item: IReport) {
  let first: DateTime | undefined;
  let last: DateTime | undefined;
  let hours = 0;

  item.samlinger.forEach((session) => {
    const start = dateFromISO(session.tidspunkt);
    if (!first || first > start) first = start;

    const end = start.plus({ minutes: session.timer * 60 });
    if (!last || last < end) last = end;

    hours += session.timer;
  });

  return {
    first: first || DateTime.now(),
    last: last || DateTime.now(),
    hours,
    sessions: item.samlinger.length,
  };
}

export function mailtoLink(email: string, number: string, clientName: string) {
  return encodeURI(
    `mailto:${email}?subject=Rapport fra gjennomført kurs (${number})&body=Hei,

Viser til rapporten til kurs nummer ${number}, levert fra ${clientName}.

## TILBAKEMELDING ##

Vennligst lever rapporten på nytt når dette er rettet opp.`
  );
}
