const config = {
  Auth: {
    identityPoolId: "eu-west-1:c7bc7a5f-c9b3-4deb-b06e-04f968723954",
    region: "eu-west-1",
    userPoolId: "eu-west-1_sCfjdJxKh",
    userPoolWebClientId: "5b6u85mq346m4bk4gljkroon9e",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "dev",
        endpoint: "https://dev.kursapi.studieforbund.no",
        region: "eu-west-1",
      },
      {
        name: "v2",
        endpoint: "https://v2.kursapi.studieforbund.no",
        region: "eu-west-1",
      },
    ],
  },
};

export default config;
