import API from "@aws-amplify/api-rest";
import React, { useReducer } from "react";
import ReportItem from "./ReportItem";
import { IReport, StageType } from "./types";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

interface IData {
  count: number;
  items: Array<IReport>;
}

interface State extends IData {
  isLoading: boolean;
  error?: string;
  pendingIds: Array<string>;
  conflictIds: Array<string>;
}

const initialState: State = {
  count: 0,
  items: [],
  isLoading: false,
  pendingIds: [],
  conflictIds: [],
};

type Action =
  | { type: "clean" }
  | { type: "request" }
  | { type: "success"; data: IData }
  | { type: "process"; id: string }
  | { type: "fail"; id: string; status?: number }
  | { type: "complete"; id: string }
  | { type: "error"; message: string };

function reportListReducer(state: State, action: Action): State {
  switch (action.type) {
    case "clean":
      return initialState;
    case "request":
      return { ...state, error: undefined, isLoading: true };
    case "success":
      return { ...state, isLoading: false, ...action.data };
    case "error":
      return { ...state, isLoading: false, error: action.message };
    case "process":
      const conflictIds = state.conflictIds.filter(
        (item) => item !== action.id
      );
      return {
        ...state,
        conflictIds,
        pendingIds: [...state.pendingIds, action.id],
      };
    case "fail": {
      const pendingIds = state.pendingIds.filter((item) => item !== action.id);
      const conflictIds =
        action.status === 409
          ? [...state.conflictIds, action.id]
          : state.conflictIds;
      return {
        ...state,
        conflictIds,
        pendingIds,
      };
    }
    case "complete": {
      const items = state.items.filter((el) => el.id !== action.id);
      const pendingIds = state.pendingIds.filter((item) => item !== action.id);
      return {
        ...state,
        items,
        pendingIds,
        count: items.length,
      };
    }
  }
}

interface Props {
  sf: string;
  stage?: StageType;
}

function ReportsList({ sf, stage = "dev" }: Props) {
  const [state, dispatch] = useReducer(reportListReducer, initialState);
  React.useEffect(() => {
    dispatch({ type: "clean" });
    dispatch({ type: "request" });
    API.get(stage, `/sf/${sf}/rapporter`, undefined)
      .then((data) => {
        dispatch({ type: "success", data });
      })
      .catch((err) => {
        dispatch({ type: "error", message: err.message });
      });
  }, [stage, sf]);

  const getRejectHandler = (id: string) => () => {
    dispatch({ type: "process", id });
    const toastId = toast.loading("Sletter rapporten...");

    API.del(stage, `/sf/${sf}/rapporter/${id}`, undefined)
      .then(() => {
        dispatch({ type: "complete", id });
        toast.update(toastId, {
          render: "Rapporten er slettet 👌",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      })
      .catch(({ message }) => {
        dispatch({ type: "fail", id });
        toast.update(toastId, {
          render: `Det oppsto en feil: ${message}`,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      });
  };

  const getAcceptHandler =
    (id: string) =>
    (override = "", overwrite = false) => {
      const queryStringParameters: Record<string, string> = {};

      if (override && override.length) {
        queryStringParameters["overstyrKursnummer"] = override;
      }

      if (overwrite) {
        queryStringParameters["overskriv"] = "true";
      }

      dispatch({ type: "process", id });
      const toastId = toast.loading("Sender rapporten til eApply...");

      API.put(stage, `/sf/${sf}/rapporter/${id}`, {
        queryStringParameters,
      })
        .then(() => {
          dispatch({ type: "complete", id });
          toast.update(toastId, {
            render: "Rapporten er levert 👌",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        })
        .catch(({ message, response }) => {
          dispatch({ type: "fail", id, status: response.status });
          toast.update(toastId, {
            render: `Det oppsto en feil: ${message}`,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        });
    };

  if (state.error) return <p>{state.error}</p>;

  if (state.isLoading) return <p>Laster inn...</p>;

  return (
    <div>
      <ToastContainer />
      {state.count === 0 ? (
        <p>Alle rapportene er levert!</p>
      ) : (
        state.items.map((item) => (
          <ReportItem
            item={item}
            key={item.id}
            pending={state.pendingIds.includes(item.id)}
            confict={state.conflictIds.includes(item.id)}
            acceptHandler={getAcceptHandler(item.id)}
            rejectHandler={getRejectHandler(item.id)}
          />
        ))
      )}
    </div>
  );
}

export default ReportsList;
