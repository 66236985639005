import React, { useEffect, useMemo, useState } from "react";
import { IReport } from "./types";
import styles from "./ReportItem.module.css";
import { numberFormat } from "./helpers/number";
import { dateTimeFormat, dateFormat } from "./helpers/date";
import { summarize, attend, mailtoLink } from "./helpers/report";
import classNames from "classnames";

interface ReportItemProps {
  item: IReport;
  pending?: boolean;
  confict?: boolean;
  acceptHandler: (override?: string, overwrite?: boolean) => void;
  rejectHandler: () => void;
}

function ReportItem({
  item,
  pending = false,
  confict = false,
  acceptHandler,
  rejectHandler,
}: ReportItemProps) {
  const [expanded, setExpanded] = useState(false);
  const [caseNumber, setCaseNumber] = useState<string>();
  const [overwrite, setOverwrite] = useState(false);

  useEffect(() => {
    setCaseNumber(item.kursnummer);
  }, [item.kursnummer]);

  const summary = useMemo(() => summarize(item), [item]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerExpanded]: expanded,
        [styles.isDisabled]: pending,
      })}
    >
      <div
        className={classNames(styles.itemHead, {
          [styles.itemHeadExpanded]: expanded,
        })}
        onClick={() => setExpanded(!expanded)}
      >
        <h2>
          {expanded ? "▽" : "▷"} {item.kursnummer}
          <small className={styles.pill}>{item.userName}</small>
        </h2>
        <div className={styles.itemHeadMeta}>
          <small>Levert {dateTimeFormat(item.mottatt)}</small>
          <span>
            {item.lokallag}
            {item.lokallag && " / "}
            <strong>{(item.larernavn || [])[0]}</strong>
          </span>
        </div>
      </div>
      {expanded && (
        <div className={styles.itemBody}>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <small>Kurslærer(e)</small>
                    <br />
                    {(item.larernavn || ["-"]).join(", ")}
                  </p>
                </td>
                <td>
                  <p>
                    <small>Telefonnummer</small>
                    <br />
                    {item.telefon}
                  </p>
                </td>
                <td>
                  <p>
                    <small>E-postadresse</small>
                    <br />
                    {item.epost}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <small>Sum inntekter</small>
                    <br />
                    {item.inntekter
                      ? `${numberFormat(item.inntekter)} kr`
                      : "-"}
                  </p>
                </td>
                <td>
                  <p>
                    <small>Sum utgifter</small>
                    <br />
                    {item.utgifter ? `${numberFormat(item.utgifter)} kr` : "-"}
                  </p>
                </td>
                <td>
                  <p>
                    <small>Dugnadstimer</small>
                    <br />
                    {item.dugnadstimer ? numberFormat(item.dugnadstimer) : "-"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <small>Kursperiode</small>
                    <br />
                    {dateFormat(summary.first)}–{dateFormat(summary.last)} (
                    {numberFormat(summary.sessions)} samlinger,{" "}
                    {numberFormat(summary.hours)} timer)
                  </p>
                </td>
                <td colSpan={2}>
                  <p>
                    <small>Merknad fra kursarrangør</small>
                    <br />
                    {item.merknad || "---"}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>
            <strong>Frammøteregistrering</strong>{" "}
            <small>(U = undervisning, S = nettbasert selvstudium)</small>
          </h3>
          <table className={styles.itemBodyAttendanceTable}>
            <tbody>
              <tr>
                <th>
                  <small>
                    Navn
                    <br />
                    Postnr./e-post/tlf.
                  </small>
                </th>
                <th>
                  <small>
                    Født
                    <br />
                    Kjønn
                  </small>
                </th>
                {item.samlinger.map((samling) => (
                  <th style={{ textAlign: "center" }} key={samling.tidspunkt}>
                    <small>
                      {dateTimeFormat(samling.tidspunkt)} (
                      {numberFormat(samling.timer)} t)
                    </small>
                  </th>
                ))}
              </tr>
              {item.deltakere.map((deltaker) => (
                <tr key={[deltaker.navn, deltaker.postnummer].join("_")}>
                  <th scope="row">
                    {deltaker.navn}
                    <br />
                    {deltaker.postnummer}, {deltaker.epost}, {deltaker.telefon}
                  </th>
                  <td>
                    {deltaker.fodselsaar}
                    <br />
                    {deltaker.kjonn}
                  </td>
                  {item.samlinger.map((samling, index) => (
                    <td
                      key={[
                        deltaker.navn,
                        deltaker.postnummer,
                        samling.tidspunkt,
                      ].join("_")}
                      className={styles.itemBodyAttendanceTableCheck}
                    >
                      {attend(
                        index + 1,
                        deltaker.tilstede,
                        deltaker.selvstudium
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <strong>
              Dokumentet er signert elektronisk av{" "}
              {(item.larernavn || ["UKJENT"])[0]} (
              <a href={mailtoLink(item.epost, item.kursnummer, item.userName)}>
                {item.epost}
              </a>
              ) hos {item.userName}.
            </strong>
            <br />
            <small>Dokumentet er mottatt {dateTimeFormat(item.mottatt)}.</small>
          </p>
          <button
            className={[styles.button, styles.rejectButton].join(" ")}
            onClick={() => {
              if (
                window.confirm(
                  `Er du sikker på at du vil slette rapporten for saksnummer ${caseNumber}?`
                )
              )
                rejectHandler();
            }}
          >
            Slett
          </button>
          <button
            className={[styles.button, styles.acceptButton].join(" ")}
            onClick={() => {
              if (
                window.confirm(
                  `Er du sikker på at du vil overføre rapporten for saksnummer ${caseNumber}?`
                )
              )
                acceptHandler(
                  caseNumber !== item.kursnummer ? caseNumber : undefined,
                  overwrite
                );
            }}
          >
            Overfør
          </button>
          <button
            onClick={() => {
              var userCaseNumber = prompt(
                "Oppgi kursnummeret denne rapporten skal knyttes til:",
                caseNumber
              );
              if (userCaseNumber) {
                setCaseNumber(userCaseNumber);
              }
            }}
          >
            Saksnummer: {caseNumber}
          </button>
          {confict && (
            <label>
              <input
                type="checkbox"
                checked={overwrite}
                onChange={(e) => setOverwrite(e.target.checked)}
              />{" "}
              Overskriv eksisterende data
            </label>
          )}
        </div>
      )}
    </div>
  );
}

export default ReportItem;
