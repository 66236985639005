import React from "react";
import {
  AmplifyAuthenticator,
  AmplifyAuthContainer,
  AmplifySignOut,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import {
  AuthState,
  CognitoUserInterface,
  onAuthUIStateChange,
} from "@aws-amplify/ui-components";

import "./App.css";
import ReportsList from "./ReportsList";
import useLocalStorage from "./hooks/useLocalStorage";
import { StageType } from "./types";

interface IUserWithAttributes extends CognitoUserInterface {
  attributes: {
    sub: string;
    email: string;
    "custom:sf": string;
  };
}

function App() {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<IUserWithAttributes | undefined>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData ? (authData as IUserWithAttributes) : undefined);
    });
  }, []);

  const [stage, setStage] = useLocalStorage<StageType>("stage", "dev");

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <header className="App-header">
        <h1>Rapportmottak</h1>
        <div className="App-header-username">
          {user.attributes.email} ({user.attributes["custom:sf"]})<br />
          <select
            onChange={(e) => setStage(e.target.value as StageType)}
            value={stage}
          >
            <option value="dev">Test (dev)</option>
            <option value="v2">Prod (v2)</option>
          </select>
        </div>
        <AmplifySignOut buttonText="Logg ut" />
      </header>
      <main className="App-main">
        <ReportsList sf={user.attributes["custom:sf"]} stage={stage} />
      </main>
    </div>
  ) : (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator>
        <AmplifySignIn
          slot="sign-in"
          headerText="Logg inn i kursrapport-konsollen"
          hideSignUp
        />
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );
}

export default App;
